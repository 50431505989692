var router = null;

export function setRouter(globalRouter) {
    router = globalRouter;
}

export async function secureGet(url) {
    var data = null;

    var retries = 3;

    while (--retries > 0) {
        try {
                var response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    }
                });

                if (response.status === 401) {
                    if (router) {
                        localStorage.removeItem("token");
                        router.push('/login');
                        retries = 0;
                    }
                }
                else if (response.status === 403) {
                    updateToken(response);
                }
                else if (response.status === 500 || response.status === 503 || response.status === 504) {
                    retries = 0;
                    router.push('/500');
                }
                else {
                    data = await response.json();
                    retries = 0;
                }
        }
        catch {
            if (retries > 1) {
                await wait(2000);
            }
            else if (router) {
                router.push('/404');
            }
        }
    }

    return data;
}

export async function securePost(url, body) {
    var data = null;

    var retries = 3;

    while (--retries > 0) {
        try {
            var response = await fetch(url, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token"),
                },
                body: JSON.stringify(body),
            });

            if (response.status === 401) {
                if (router) {
                    localStorage.removeItem("token");
                    router.push('/login');
                    retries = 0;
                }
            }
            else if (response.status === 403) {
                updateToken(response);
            }
            else if (response.status === 500 || response.status === 503 || response.status === 504) {
                retries = 0;
                router.push('/500');
            }
            else {
                data = await response.json();
                retries = 0;
            }
        }
        catch {
            if (retries > 1) {
                await wait(2000);
            }
            else if (router) {
                router.push('/404');
            }
        }
    }

    return data;
}

export async function securePatch(url, body) {
  var data = null;

  var retries = 3;

  while (--retries > 0) {
    try {
      var response = await fetch(url, {
        method: 'PATCH',
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(body),
      });

      if (response.status === 401) {
        if (router) {
          localStorage.removeItem("token");
          router.push('/login');
          retries = 0;
        }
      }
      else if (response.status === 403) {
        updateToken(response);
      }
      else {
        data = await response.json();
        retries = 0;
      }
    }
    catch {
      if (retries > 1) {
        await wait(2000);
      }
      else if (router) {
        router.push('/404');
      }
    }
  }

  return data;
}

export async function securePut(url, body) {
    var data = null;

    var retries = 3;

    while (--retries > 0) {
        try {
            var response = await fetch(url, {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token"),
                },
                body: JSON.stringify(body),
            });

            if (response.status === 401) {
                if (router) {
                    localStorage.removeItem("token");
                    router.push('/login');
                    retries = 0;
                }
            }
            else if (response.status === 403) {
                updateToken(response);
            }
            else if (response.status === 500 || response.status === 503 || response.status === 504) {
                retries = 0;
                router.push('/500');
            }
            else {
                data = await response.json();
                retries = 0;
            }
        }
        catch {
            if (retries > 1) {
                await wait(2000);
            }
            else if (router) {
                router.push('/404');
            }
        }
    }

    return data;
}

export async function secureDelete(url) {
    var data = null;

    var retries = 3;

    while (--retries > 0) {
        try {
            var response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("token")
                }
            });

            if (response.status === 401) {
                if (router) {
                    localStorage.removeItem("token");
                    router.push('/login');
                    retries = 0;
                }
            }
            else if (response.status === 403) {
                updateToken(response);
            }
            else if (response.status === 500 || response.status === 503 || response.status === 504) {
                retries = 0;
                router.push('/500');
            }
            else {
                data = await response.json();
                retries = 0;
            }
        }
        catch {
            if (retries > 1) {
                await wait(2000);
            }
            else if (router) {
                router.push('/404');
            }
        }
    }

    return data;
}

export async function secureForm(url, formData) {
    var data = null;

    var retries = 3;

    while (--retries > 0) {
        try {
            var response = await fetch(url, {
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("token"),
                },
                body: formData,
            });

            if (response.status === 401) {
                if (router) {
                    localStorage.removeItem("token");
                    router.push('/login');
                    retries = 0;
                }
            }
            else if (response.status === 403) {
                updateToken(response);
            }
            else if (response.status === 500 || response.status === 503 || response.status === 504) {
                retries = 0;
                router.push('/500');
            }
            else {
                data = await response.json();
                retries = 0;
            }
        }
        catch {
            if (retries > 1) {
                await wait(2000);
            }
            else if (router) {
                router.push('/404');
            }
        }
    }

    return data;
}

function updateToken(response) {
    if (response.headers.has("Updated-Access-Token")) {
        localStorage.setItem("token", response.headers.get('Updated-Access-Token'));
    }
}

function wait(ms) {
    return new Promise(resolve => {
        setTimeout(() => { resolve('') }, ms);
    });
}