<template>
    <div class="hero">
        <div class="hero-body container">
            <div class="has-text-centered login">
                <CRUDy />

                <p v-if="errorMessage" class="help is-danger login-error">{{ errorMessage }}<a v-if="twoFactorSetupUrl" :href="twoFactorSetupUrl" target="_blank">Setup Now</a></p>

                <fieldset :disabled="isLoading">
                    <div class="field has-text-left">
                        <label class="label">Environment</label>
                        <div class="dropdown" :class="{ 'is-active': environmentDropdownActive }">
                            <div class="dropdown-trigger">
                                <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="environmentDropdownActive = !environmentDropdownActive" @blur="delayCloseEnvironmentDropdown">
                                    <span class="is-flex-grow-1 has-text-left">{{ environmentDictionary[currentEnvironment] }}</span>
                                    <span class="icon is-small">
                                        <font-awesome-icon :icon="['fa', 'angle-down']" />
                                    </span>
                                </button>
                            </div>
                            <div class="dropdown-menu" id="dropdown-menu" role="menu">
                                <div class="dropdown-content">
                                    <a v-for="(index, environmentKey) in environmentDictionary" :key="'environment' + index"
                                        :class="currentEnvironment == environmentKey ? 'dropdown-item is-active' : 'dropdown-item'" @click="setEnvironment(environmentKey)">
                                        {{ environmentDictionary[environmentKey] }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="field has-text-left">
                        <label class="label">Email</label>
                        <div class="control has-icons-left has-icons-right">
                            <input v-model="email" class="input" type="email" name="email" placeholder="Enter email" @keydown.enter="login" autofocus />
                            <span class="icon is-small is-left">
                                <font-awesome-icon :icon="['fa', 'envelope']" />
                            </span>
                        </div>
                    </div>

                    <div class="field has-text-left">
                        <label class="label">Password</label>
                        <div class="control has-icons-left has-icons-right">
                            <input v-model="password" class="input" type="password" name="password" placeholder="Enter password" @keydown.enter="login" />
                            <span class="icon is-small is-left">
                                <font-awesome-icon :icon="['fa', 'key']" />
                            </span>
                        </div>
                    </div>

                    <div class="field has-text-left" v-if="this.twoFactorRequired">
                        <label class="label">Two Factor Code</label>
                        <div class="control has-icons-left has-icons-right">
                            <input v-model="twoFactorCode" class="input no-arrows" type="number" name="Two Factor Code" placeholder="Enter code." @keydown.enter="login" autofocus />
                            <span class="icon is-small is-left">
                                <font-awesome-icon :icon="['fa', 'shield-alt']" />
                            </span>
                        </div>
                    </div>

                    <div class="field has-text-left" v-if="this.twoFactorRequired">
                        <label class="switch-label">
                            <input type="checkbox" class="switch" v-model="rememberDevice" /> Remember this device
                        </label>
                    </div>

                    <div class="field is-grouped is-grouped-right">
                        <div class="control">
                            <button :class="isLoading ? 'button is-primary is-loading' : 'button is-primary'" @click="login">
                                Log in
                            </button>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>
</template>

<script>
    import CRUDy from "../components/CRUDy";

    export default {
        name: "Login",
        components: {
            CRUDy
        },
        computed: {
            hostUrl: function() {
                return this.$store.state.hostUrl;
            },
            currentEnvironment: function() {
                return this.$store.state.environment;
            },
            environmentDictionary: function() {
                return this.$store.state.environmentDictionary;
            },
            platformUrl: function() {
                return this.$store.state.platformUrl;
            }
        },
        data: function () {
            return {
                email: null,
                password: null,
                twoFactorCode: null,
                twoFactorRequired: false,
                showTwoFactorSetupUrl: false,
                environmentDropdownActive: false,
                isLoading: false,
                errorMessage: null,
                twoFactorSetupUrl: null,
                rememberDevice: false,
                defaultErrorMessage: "Nope - can't log in. Shit's broke. I don't even know. Everything's on fire. \n-Julia, probably"
            };
        },
        methods: {
            login() {
                this.isLoading = true;
                this.errorMessage = null;
                this.showTwoFactorSetupUrl = false;

                const authenticateUserRequest = {
                    email: this.email,
                    password: this.password,
                    twoFactorCode: this.twoFactorCode,
                    twoFactorMethodType: 1,
                    rememberDevice: this.rememberDevice
                };

                fetch(this.hostUrl + "v1/user/login", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(authenticateUserRequest)
                })
                    .then((response) => response.json())
                    .then((data) => {
                        this.isLoading = false;
                        if (data.success) {
                            if (data.twoFactorSetupRequired) {
                                this.twoFactorSetupUrl = this.platformUrl + "two-factor/setup";
                                this.errorMessage = "Two factor setup is required. ";
                                this.twoFactorRequired = true;
                            }
                            else if (data.twoFactorRequired)
                            {
                                this.twoFactorRequired = true;
                                this.errorMessage = "Enter your 2FA code.";
                            }
                            else
                            {
                                localStorage.setItem("token", data.token);
                                this.$router.push("/");
                            }
                        }
                        else {
                            this.errorMessage = data.message;

                            if (!this.errorMessage) {
                                this.errorMessage = this.defaultErrorMessage;
                            }
                        }
                    })
                    .catch((e) => {
                        this.isLoading = false;
                        this.errorMessage = this.defaultErrorMessage;
                        console.error(e);
                    });
            },
            delayCloseEnvironmentDropdown() {
                setTimeout(() => this.environmentDropdownActive = false, 250);
            },
            setEnvironment(environment) {
                this.environmentDropdownActive = false;
                this.$store.commit('updateEnvironment', environment);
            }
        }
    };
</script>

<style scoped>
    .login {
        max-width: 25em;
        min-width: 18em;
    }

    .login-error {
        max-width: 25em;
        margin-bottom: 1em;
        white-space: pre-line;
        text-align: left;
    }

   .dropdown {
        width: 100%;
    }

    .dropdown-trigger {
        width: 100%;
    }

    .dropdown-trigger > .button {
        width: 100%;
    }

    /* Chrome, Safari, Edge, Opera */
    input.no-arrows::-webkit-outer-spin-button,
    input.no-arrows::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input.no-arrows[type=number] {
        -moz-appearance: textfield;
    }
</style>