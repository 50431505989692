import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    environment: 0,
    environmentDictionary: { 0: 'Local', 1: 'Test', 2: 'Production', 3: 'FR - Production' },
    hostUrl: 'https://localhost:8499/api/crudy/',
    platformUrl: 'https://localhost:8081/',
    accountFilters: {
      searchTerm: '',
      page: 0,
      status: null,
      integration: null
    },
    userFilters: {
      searchTerm: '',
      page: 0,
      accountPublicId: null,
      userRole: null,
      userStatus: null
    },
    lineFilters: {
      searchTerm: '',
      page: 0,
      accountPublicId: null,
      integration: null,
      textingService: null,
      isEnabled: null,
    },
    jobHistoryFilters: {
      page: 0,
      filterByUser: false,
    },
  },
  mutations: {
    updateEnvironment(state, environment) {
      state.environment = environment;

      if (environment == 0) {
        state.hostUrl = 'https://localhost:8499/api/crudy/';
        state.platformUrl = 'https://localhost:8081/';
      }
      else if (environment == 1) {
        state.hostUrl = 'https://foundation-test.textel.net/api/crudy/';
        state.platformUrl = 'https://app-test.textel.net/';
      }
      else if (environment == 2) {
        state.hostUrl = 'https://foundation.textel.net/api/crudy/';
        state.platformUrl = 'https://app.textel.net/';
          }
      else if (environment == 3) {
        state.hostUrl = 'https://foundation.textel.eu/api/crudy/';
        state.platformUrl = 'https://app.textel.eu/';
      }

      localStorage.setItem("environment", environment);
    },
    updateAccountSearchFilter(state, searchTerm) {
      state.accountFilters.searchTerm = searchTerm;
    },
    updateAccountPageFilter(state, page) {
      state.accountFilters.page = page;
    },
    updateAccountStatusFilter(state, status) {
      state.accountFilters.status = status;
    },
    updateAccountIntegrationFilter(state, integration) {
      state.accountFilters.integration = integration;
    },
    updateUserSearchFilter(state, searchTerm) {
      state.userFilters.searchTerm = searchTerm;
    },
    updateUserPageFilter(state, page) {
      state.userFilters.page = page;
    },
    updateUserAccountIdFilter(state, id) {
      state.userFilters.accountPublicId = id;
    },
    updateUserRoleFilter(state, role) {
      state.userFilters.userRole = role;
    },
    updateUserStatusFilter(state, status) {
      state.userFilters.userStatus = status;
    },
    updateLineSearchFilter(state, searchTerm) {
      state.lineFilters.searchTerm = searchTerm;
    },
    updateLinePageFilter(state, page) {
      state.lineFilters.page = page;
    },
    updateLineAccountIdFilter(state, id) {
      state.lineFilters.accountPublicId = id;
    },
    updateJobHistoryPageFilter(state, page) {
      state.jobHistoryFilters.page = page;
    },
    updateJobHistoryFilterByUser(state, filterByUser) {
      state.jobHistoryFilters.filterByUser = filterByUser;
    },
    updateLineIntegrationFilter(state, integration) {
      state.lineFilters.integration = integration;
    },
    updateLineTextingServiceFilter(state, textingService) {
      state.lineFilters.textingService = textingService;
    },
    updateLineIsEnabledFilter(state, isEnabled) {
      state.lineFilters.isEnabled = isEnabled;
    }
  },
  actions: {
    clearAccountFilters(context) {
      context.commit('updateAccountSearchFilter', '');
      context.commit('updateAccountPageFilter', 0);
      context.commit('updateAccountStatusFilter', null);
      context.commit('updateAccountIntegrationFilter', null);
    },
    clearUserFilters(context) {
      context.commit('updateUserSearchFilter', '');
      context.commit('updateUserPageFilter', 0);
      context.commit('updateUserAccountIdFilter', null);
      context.commit('updateUserRoleFilter', null);
      context.commit('updateUserStatusFilter', null);
    },
    clearLineFilters(context) {
      context.commit('updateLineSearchFilter', '');
      context.commit('updateLinePageFilter', 0);
      context.commit('updateLineAccountIdFilter', null);
      context.commit('updateLineIntegrationFilter', null);
      context.commit('updateLineTextingServiceFilter', null);
      context.commit('updateLineIsEnabledFilter', null);
    },
    clearJobHistoryFilters(context) {
      context.commit("updateJobHistoryPageFilter", 0);
    },
  },
  modules: {
  }
})
