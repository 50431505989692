import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import { setRouter } from '../secureFetch.js'

Vue.use(VueRouter)

const routes = [
  { path: '/login', name: 'Login', component: Login },
  { path: '/', name: "Dashboard", component: Dashboard },
  { path: '/accounts', name: "Accounts", component: () => import(/* webpackChunkName: "accounts" */ '../views/Accounts.vue')  },
  { path: '/account/:publicAccountId?', name: "Account", component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue')  },
  { path: '/users', name: "Users", component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue')  },
  { path: '/user/:userId?', name: "User", component: () => import(/* webpackChunkName: "user" */ '../views/User.vue')  },
  { path: '/lines', name: "CustomerLines", component: () => import(/* webpackChunkName: "lines" */ '../views/CustomerLines.vue')  },
  { path: '/line/:publicLineId?', name: "CustomerLine", component: () => import(/* webpackChunkName: "line" */ '../views/CustomerLine.vue')  },
  { path: '/permissions', name: "PermissionTemplates", component: () => import(/* webpackChunkName: "permissions" */ '../views/PermissionTemplates.vue')  },
  { path: '/permissionTemplate/:templateId?', name: "PermissionTemplate", component: () => import(/* webpackChunkName: "permissionTemplate" */ '../views/PermissionTemplate.vue')  },
  { path: '/products', name: "Products", component: () => import(/* webpackChunkName: "products" */ '../views/Products.vue')  },
  { path: '/product/:productId?', name: "Product", component: () => import(/* webpackChunkName: "product" */ '../views/Product.vue')  },
  { path: '/queues', name: "Queues", component: () => import(/* webpackChunkName: "queues" */ '../views/Queues.vue')  },
  { path: '/utilities', name: "Utilities", component: () => import(/* webpackChunkName: "utilities" */ '../views/Utilities.vue')  },
  { path: '/cleanup', name: "Cleanup", component: () => import(/* webpackChunkName: "cleanup" */ '../views/Cleanup.vue')  },
  { path: '/settings', name: "Settings", component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue')  },
  { path: '/404', name: 'NotFound', component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue')  },
  { path: '/500', name: 'ServerError', component: () => import(/* webpackChunkName: "server-error" */ '../views/ServerError.vue')},
  { path: '*', redirect: '404' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === "Login") {
      if (localStorage.getItem('token')) {
          next({ name: 'Dashboard' })
      }
      else {
          next()
      }
  }
  else if (localStorage.getItem('token')) {
      next()
  }
  else {
      next({ name: 'Login' })
  }
})

setRouter(router)

export default router
