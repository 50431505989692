<template>
    <div class="dashboard">
        <h1 class="title mb-6">CRUDy Dashboard</h1>
        <p class="subtitle">TODO: Add information that's useful at a glance.</p>

        <div class="columns is-multiline">
            <div class="column is-one-third">
                <div class="box">
                    <router-link to="/accounts">
                        <div class="media">
                            <div class="media-left">
                                <span class="icon mt-1">
                                    <font-awesome-icon :icon="['far', 'user-circle']" size="2x" />
                                </span>
                            </div>
                            <div class="media-content">
                                <p class="title is-4">Accounts</p>
                                <p class="subtitle is-5">Search and manage accounts.</p>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>

            <div class="column is-one-third">
                <div class="box">
                    <router-link to="/users">
                        <div class="media">
                            <div class="media-left">
                                <span class="icon mt-1">
                                    <font-awesome-icon :icon="['fa', 'user-friends']" size="2x" />
                                </span>
                            </div>
                            <div class="media-content">
                                <p class="title is-4">Users</p>
                                <p class="subtitle is-5">Search and manage users.</p>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>

            <div class="column is-one-third">
                <div class="box">
                    <router-link to="/lines">
                        <div class="media">
                            <div class="media-left">
                                <span class="icon mt-1">
                                    <font-awesome-icon :icon="['fa', 'phone-alt']" size="2x" />
                                </span>
                            </div>
                            <div class="media-content">
                                <p class="title is-4">Lines</p>
                                <p class="subtitle is-5">Manage customer lines.</p>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>

            <div class="column is-one-third">
                <div class="box">
                    <router-link to="/permissions">
                        <div class="media">
                            <div class="media-left">
                                <span class="icon mt-1">
                                    <font-awesome-icon :icon="['far', 'dot-circle']" size="2x" />
                                </span>
                            </div>
                            <div class="media-content">
                                <p class="title is-4">Permissions</p>
                                <p class="subtitle is-5">Manage permission templates.</p>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>

            <div class="column is-one-third">
                <div class="box">
                    <router-link to="/products">
                        <div class="media">
                            <div class="media-left">
                                <span class="icon mt-1">
                                    <font-awesome-icon :icon="['far', 'window-maximize']" size="2x" />
                                </span>
                            </div>
                            <div class="media-content">
                                <p class="title is-4">Products</p>
                                <p class="subtitle is-5">Manage products and features.</p>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>

            <div class="column is-one-third">
                <div class="box">
                    <router-link to="/queues">
                        <div class="media">
                            <div class="media-left">
                                <span class="icon mt-1">
                                    <font-awesome-icon :icon="['fa', 'stream']" size="2x" />
                                </span>
                            </div>
                            <div class="media-content">
                                <p class="title is-4">Queues</p>
                                <p class="subtitle is-5">Monitor message queues.</p>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>

            <div class="column is-one-third">
                <div class="box">
                    <router-link to="/utilities">
                        <div class="media">
                            <div class="media-left">
                                <span class="icon mt-1">
                                    <font-awesome-icon :icon="['fa', 'tools']" size="2x" />
                                </span>
                            </div>
                            <div class="media-content">
                                <p class="title is-4">Utilities</p>
                                <p class="subtitle is-5">Data utilites and jobs.</p>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Dashboard',
    data() {
        return {
            isLoading: true
        }
    },
    mounted() {
    }
};
</script>

<style scoped>
</style>
