import Vue from 'vue'
import Buefy from 'buefy'
import App from './App.vue'
import router from './router'
import store from './store'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faBug, faSearch, faUser, faKey, faPen, faFileCode, faUpload, faImages, faInfoCircle, 
    faPhoneAlt, faUserFriends, faCogs, faStream, faPlus, faEnvelope, faSortAlphaUp, faSortAlphaDown,
    faSortUp, faSortDown, faTrashAlt, faAngleDown, faAngleUp, faChartBar, faBroom, faFilter, faTimesCircle, 
    faDownload, faRedo, faShieldAlt, faTools, faSync, faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons'
import { faUserCircle, faWindowMaximize, faDotCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { ConfigProgrammatic } from 'buefy'

library.add(faBug, faSearch, faUser, faKey, faPen, faFileCode, faUpload, faImages, faInfoCircle, faDotCircle,
    faUserCircle, faPhoneAlt, faUserFriends, faCogs, faStream, faPlus, faEnvelope, faSortAlphaUp, faSortAlphaDown,
    faSortUp, faSortDown, faTrashAlt, faAngleDown, faAngleUp, faChartBar, faWindowMaximize, faBroom, faFilter, faTimesCircle,
    faDownload, faRedo, faShieldAlt, faTools, faSync, faExclamationCircle)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(Buefy);
ConfigProgrammatic.setOptions({
  defaultIconPack: 'fas'
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')