<template>
    <pre class="crudy">  ______ ______  _     _ _____         
 / _____|_____ \| |   | (____ \        
| /      _____) ) |   | |_   \ \ _   _ 
| |     (_____ (| |   | | |   | | | | |
| \_____      | | |___| | |__/ /| |_| |
 \______)     |_|\______|_____/  \__  |
                                (____/ </pre>
</template>

<script>
    export default {
        name: 'CRUDy'
    };
</script>

<style scoped>
    .crudy {
        background-color: transparent;
        font-size: .3em;
    }
</style>