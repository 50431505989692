<template>
  <div>
    <div v-if="isLoggedIn" class="is-pulled-left has-background-dark left-nav">
      <aside class="menu">
        <nav class="level mb-0">
          <div class="level-left">
            <div class="level-item">
                <CRUDy />
            </div>

            <div class="level-item">
              <div class="dropdown is-hoverable mt-1">
                <div class="dropdown-trigger">
                  <button class="button is-text" aria-haspopup="true" aria-controls="dropdown-menu4">
                    <span class="icon">
                      <font-awesome-icon :icon="['fa', 'user']" />
                    </span>
                    <span class="icon is-small">
                      <font-awesome-icon :icon="['fa', 'angle-down']" />
                    </span>
                  </button>
                </div>
                <div class="dropdown-menu" id="dropdown-menu4" role="menu">
                  <div class="dropdown-content">
                      <router-link class="dropdown-item" :to="'/user/' + userId" @click.native="navbarExpanded = false">
                          User Settings
                      </router-link>
                      <a class="dropdown-item" role="button" @click="logout">
                          Logout
                      </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>

        <div class="tags has-addons ml-5">
            <span class="tag is-light">Environment</span>
            <span class="tag is-primary">{{currentEnvironment}}</span>
        </div>

        <ul class="menu-list">
            <li>
                <router-link :class="{ 'is-active': routeIsDashboard }" to="/">
                    <span class="icon-text mt-2">
                        <span class="icon">
                            <font-awesome-icon :icon="['fa', 'chart-bar']" />
                        </span>
                        <span class="ml-2">Dashboard</span>
                    </span>
                </router-link>
            </li>
            <li>
                <router-link :class="{ 'is-active': routeIsAccounts }" to="/accounts">
                     <span class="icon-text mt-2">
                        <span class="icon">
                            <font-awesome-icon :icon="['far', 'user-circle']" />
                        </span>
                        <span class="ml-2">Accounts</span>
                    </span>
                </router-link>
            </li>
            <li>
                <router-link :class="{ 'is-active': routeIsUsers }" to="/users">
                     <span class="icon-text mt-2">
                        <span class="icon">
                            <font-awesome-icon :icon="['fa', 'user-friends']" />
                        </span>
                        <span class="ml-2">Users</span>
                    </span>
                </router-link>
            </li>
            <li>
                <router-link :class="{ 'is-active': routeIsLines }" to="/lines">
                     <span class="icon-text mt-2">
                        <span class="icon">
                            <font-awesome-icon :icon="['fa', 'phone-alt']" />
                        </span>
                        <span class="ml-2">Customer Lines</span>
                    </span>
                </router-link>
            </li>
            <li>
                <router-link :class="{ 'is-active': routeIsPermissions }" to="/permissions">
                     <span class="icon-text mt-2">
                        <span class="icon">
                            <font-awesome-icon :icon="['far', 'dot-circle']" />
                        </span>
                        <span class="ml-2">Permissions</span>
                    </span>
                </router-link>
            </li>
            <li>
                <router-link :class="{ 'is-active': routeIsProducts }" to="/products">
                     <span class="icon-text mt-2">
                        <span class="icon">
                            <font-awesome-icon :icon="['far', 'window-maximize']" />
                        </span>
                        <span class="ml-2">Products</span>
                    </span>
                </router-link>
            </li>
            <li>
                <router-link :class="{ 'is-active': routeIsQueues }" to="/queues">
                    <span class="icon-text mt-2">
                        <span class="icon">
                            <font-awesome-icon :icon="['fa', 'stream']" />
                        </span>
                        <span class="ml-2">Queues &amp; Topics</span>
                    </span>
                </router-link>
            </li>
            <li>
                <router-link :class="{ 'is-active': routeIsUtilities }" to="/utilities">
                    <span class="icon-text mt-2">
                        <span class="icon">
                            <font-awesome-icon :icon="['fa', 'tools']" />
                        </span>
                        <span class="ml-2">Utilities</span>
                    </span>
                </router-link>
            </li>
            <li>
                <router-link :class="{ 'is-active': routeIsCleanup }" to="/cleanup">
                    <span class="icon-text mt-2">
                        <span class="icon">
                            <font-awesome-icon :icon="['fa', 'broom']" />
                        </span>
                        <span class="ml-2">Cleanup</span>
                    </span>
                </router-link>
            </li>
        </ul>
      </aside>

      <div class="left-nav-fill"></div>

        <aside class="menu">
            <ul class="menu-list">
                <li>
                    <router-link :class="{ 'is-active': routeIsSettings }" to="/settings">
                        <span class="icon-text mt-2">
                        <span class="icon">
                            <font-awesome-icon :icon="['fa', 'cogs']" />
                        </span>
                        <span class="ml-2">Settings</span>
                    </span>
                    </router-link>
                </li>
            </ul>
        </aside>
    </div>

      <div id="column-right" class="column-right">
        <div class="main-container">
            <router-view></router-view>
        </div>
      </div>
    </div>
</template>

<script>
    import { securePost } from './secureFetch.js';

    import CRUDy from "./components/CRUDy";

    export default {
        name: "App",
        components: {
            CRUDy
        },
        data: function () {
            return {
                navbarExpanded: false,
                isLoggedIn: false,
                permissions: null,
                userId: null,
            };
        },
        computed: {
            routeIsDashboard () {
                return this.$route.name === "Dashboard";
            },
            routeIsAccounts () {
                return this.$route.name === "Accounts" || this.$route.name === "Account";
            },
            routeIsUsers () {
                return this.$route.name === "Users" || this.$route.name === "User";
            },
            routeIsLines () {
                return this.$route.name === "CustomerLines" || this.$route.name === "CustomerLine";
            },
            routeIsProducts () {
                return this.$route.name === "Product" || this.$route.name === "Products";
            },
            routeIsPermissions () {
                return this.$route.name === "PermissionTemplate" || this.$route.name === "PermissionTemplates";
            },
            routeIsQueues () {
                return this.$route.name === "Queues";
            },
            routeIsUtilities () {
                return this.$route.name === "Utilities";
            },
            routeIsCleanup () {
                return this.$route.name === "Cleanup";
            },
            routeIsSettings () {
                return this.$route.name === "Settings";
            },
            currentEnvironment() {
                return this.$store.state.environmentDictionary[this.$store.state.environment];
            },
            hostUrl: function() {
                return this.$store.state.hostUrl;
            }
        },
        created() {
            this.isLoggedIn = localStorage.getItem("token") !== null;
            this.parseTokenData();
            this.parseEnvironmentData();
        },
        beforeUpdate() {
            this.isLoggedIn = localStorage.getItem("token") !== null;
            this.parseTokenData();
        },
        methods: {
            toggleNavbar() {
                this.navbarExpanded = !this.navbarExpanded;
            },
            parseTokenData() {
                if (this.isLoggedIn) {
                    let token = localStorage.getItem("token").split('.')[1];
                    let tokenData = JSON.parse(atob(token));

                    this.userId = tokenData.nameid;
                    this.permissions = JSON.parse(tokenData.Permissions);
                }
            },
            parseEnvironmentData() {
                let environment = localStorage.getItem("environment");
                if (environment) {
                    this.$store.commit('updateEnvironment', environment);
                }
            },
            logout() {
                securePost(this.hostUrl + "v1/user/logout", {})
                        .then((data) => {
                            if (data.success) {
                                this.$store.dispatch('clearAccountFilters');
                                this.$store.dispatch('clearUserFilters');
                                this.$store.dispatch('clearLineFilters');
                                this.$store.dispatch('clearJobHistoryFilters');

                                localStorage.removeItem("token");
                                localStorage.removeItem("environment");

                                this.$router.push("/login");
                            }
                        })
                        .catch((e) => {
                            console.error(e);
                        });
            }
        },
    };
</script>

<style>
    html {
        --scrollbarBG: #0f0f0f;
        --thumbBG: #363636;
        --thumbBGHover: #2e2e2e;
        overflow-y: hidden;
    }

    .navbar-item {
        margin-left: 0.15em;
        margin-right: 0.15em;
    }

    .navbar.is-dark .navbar-start > a.navbar-item:focus, 
    .navbar.is-dark .navbar-start > a.navbar-item:hover,
    .navbar.is-dark .navbar-start > a.navbar-item.is-active,
    .navbar.is-dark .navbar-start .navbar-link:focus, 
    .navbar.is-dark .navbar-start .navbar-link:hover,
    .navbar.is-dark .navbar-start .navbar-link.is-active, 
    .navbar.is-dark .navbar-end > a.navbar-item:focus,
    .navbar.is-dark .navbar-end > a.navbar-item:hover, 
    .navbar.is-dark .navbar-end > a.navbar-item.is-active,
    .navbar.is-dark .navbar-end .navbar-link:focus, 
    .navbar.is-dark .navbar-end .navbar-link:hover, .navbar.is-dark
    .navbar-end .navbar-link.is-active {
        background-color: hsl(194, 15%, 8%);
        color: #f9f9f9;
        font-weight: 400;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: whitesmoke !important;
    }

    body {
        font-family: Hack, consolas, Menlo-Regular, Menlo, Monaco, "ubuntu mono", monospace, monospace;
        overflow-y: hidden;
    }

    .main-container {
        padding-bottom: 2em;
        padding-top: 2em;
        padding-left: 1em;
        padding-right: 1em;
        width: 100%;
        max-width: 1344px;
        margin: 0 auto;
    }

    .column-right {
        display: block;
        padding: .75rem;
        height: 100vh;
        overflow-y: auto;
    }

       .column-right::-webkit-scrollbar {
            width: 15px;
        }

        .column-right::-webkit-scrollbar-track {
            background: var(--scrollbarBG);
        }

        .column-right::-webkit-scrollbar-thumb {
            background-color: var(--thumbBG);
            border-radius: 9px;
            border: 2px solid var(--scrollbarBG);
        }

            .column-right::-webkit-scrollbar-thumb:hover {
                background-color: var(--thumbBGHover);
            }

    .is-full-height {
        display: flex;
        flex-flow: column;
        min-height: 100vh;
    }

    .modal-card {
        padding: 0em 0.5em;
    }

    .breadcrumb ul {
        white-space: pre-wrap;
    }

    .box {
        border: 1px solid rgb(100 100 100 / 15%);
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        -webkit-transition-duration: 0.15s;
        transition-duration: 0.15s;
        -webkit-transition-property: box-shadow;
        transition-property: box-shadow;
        box-shadow: 0 0.5em 1em -0.125em rgb(100 100 100 / 10%), 0 0px 0 1px rgb(100 100 100 / 2%);
    }
        .box:hover, .box:focus, .box:active {
            box-shadow: 0 0.5em 1em -0.125em rgb(100 100 100 / 15%), 0 0px 0 1px rgb(100 100 100 / 2%);
        }

    svg:not(:root).svg-inline--fa {
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: color;
        transition-property: color;
    }

    .navbar.is-spaced .navbar-dropdown, .navbar-dropdown.is-boxed {
        border-radius: 6px;
        border-top: none;
        box-shadow: 0 8px 8px rgb(100 100 100 / 10%), 0 0 0 1px rgb(100 100 100 / 10%);
        display: block;
        opacity: 0;
        pointer-events: none;
        top: calc(100% + (-4px));
        transform: translateY(-5px);
        transition-duration: 86ms;
        transition-property: opacity, transform;
    }

    .table th {
        padding: .5em .75em .5em 0em;
    }

    .has-top-border {
        border-top: 1px solid grey;
        padding-top: 1em;
        margin-top: 1em;
    }

    .has-bottom-border {
        margin-bottom: 1.5em;
        border-bottom: 2px solid #dcdcdc;
    }

    a {
        -webkit-transition-duration: 0.15s;
        transition-duration: 0.15s;
        -webkit-transition-property: color;
        transition-property: color;
    }

    .table-input {
        border-width: 0px;
        border-color: transparent;
        box-shadow: unset;
    }

    input.switch {
        position: relative;
        appearance: none;
        outline: none;
        width: 30px;
        height: 16px;
        background-color: #aeb5b0;
        border: 1px solid #D9DADC;
        border-radius: 25px;
        box-shadow: inset -14px 0 0 0 #ffffff;
        transition-duration: 200ms;
    }

    input.switch:after {
        content: "";
        position: absolute;
        cursor: pointer;
        top: 1px;
        left: 1px;
        width: 13px;
        height: 13px;
        background-color: transparent;
        border-radius: 50%;
        box-shadow: 2px 4px 6px rgba(0,0,0,0.2);
    }

        input.switch:checked {
            border-color: hsl(213, 48.6%, 25.2%);
            box-shadow: inset 14px 0 0 0 hsl(213, 48.6%, 25.2%);
        }

    input.switch:checked:after {
        left: 14px;
        box-shadow: -2px 4px 3px rgba(0,0,0,0.05);
    }

    label.switch-label input[type=checkbox] {
        position: relative;
        vertical-align: middle;
        bottom: 1px;
    }

    .left-nav {
        display: flex;
        flex-flow: column;
        min-height: 100vh;
        width: 16em;
    }

    .left-nav-fill {
        flex: 1;
    }

    .menu-list {
      font-size: 1.15em;
    }
</style>
